import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import BackgroundGradient from "../components/BackgroundGradient";
import BlogPostCard from "../components/cards/BlogPostCard";
import SignupFormCard from "../components/SignupFormCard";
import { useGlobalState } from "../state/globalState";
import { BlogCategory } from "../types/blog";

export type postCategoryFilterType = "all" | string;

export interface BlogProps {
  data: {
    posts: {
      edges: {
        node: BlogQueryDataPost;
      }[];
    };
    categories: {
      edges: {
        node: BlogQueryDataCategory;
      }[];
    };
  };
}

export interface BlogQueryDataPost {
  id: string;
  slug: {
    current: string;
  };
  category: BlogQueryDataCategory["category"];
  featuredImage: {
    asset: {
      // gatsbyImageData: IGatsbyImageData;
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
  largeFeaturedImage: {
    asset: {
      // gatsbyImageData: IGatsbyImageData;
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
  title: string;
  excerpt: string;
}

export interface BlogQueryDataCategory {
  category: {
    title: string;
    slug: {
      current: string;
    };
  };
}

const Blog: React.FC<BlogProps> = ({
  data: {
    posts: { edges: posts },
  },
}) => {
  const { t } = useTranslation(["blog"]);
  const [categoryFilter, setCategoryFilter] =
    React.useState<postCategoryFilterType>("all");

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: false,
    });
  }, []);

  const heroPost = React.useMemo(() => {
    return posts.slice(0, 1)[0].node;
  }, []);

  const categories = React.useMemo(
    () =>
      posts.reduce<BlogCategory[]>((acc, { node }) => {
        if (
          node.category &&
          node.category.title &&
          acc.findIndex((c) => c.title === node.category.title) === -1
        ) {
          acc.push({
            title: node.category.title,
            slug: node.category.slug.current,
          });
        }
        return acc;
      }, []),
    [posts]
  );

  const filteredPosts = React.useMemo(
    () =>
      posts
        .slice(1)
        .filter(({ node: p }) =>
          categoryFilter === "all"
            ? true
            : p.category.slug.current === categoryFilter
        ),
    [categoryFilter]
  );

  const handleCategoryChange = React.useCallback(
    (value: postCategoryFilterType) => {
      setCategoryFilter(value);
    },
    [categoryFilter]
  );

  return (
    <>
      <div className="relative w-full md:h-160 lg:h-192">
        <Link
          to={`/blog/${heroPost.slug.current}`}
          className="relative block h-full cursor-pointer"
        >
          {heroPost.largeFeaturedImage.asset && (
            <GatsbyImage
              // image={heroPost.largeFeaturedImage.asset.gatsbyImageData}
              image={
                heroPost.largeFeaturedImage.asset.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={heroPost.title}
              className="w-full h-96 md:h-full"
            />
          )}
          <BackgroundGradient className="top-0 z-10 w-full h-40" />
          <div className="inset-x-0 grid grid-cols-2 gap-8 py-4 container-5xl md:absolute bottom-4 lg:bottom-8">
            <div className="col-span-2 col-start-1 p-6 space-y-4 bg-white rounded-xl md:col-span-1 md:col-start-2">
              <h2 className="text-2xl font-bold font-headline md:text-4xl truncated-headline">
                {heroPost.title}
              </h2>
              <p className="text-base text-gray-700">
                {`${heroPost.excerpt.substring(0, 200)}...`}
              </p>
              <div>
                <span className="button-blue-filled">
                  {t("blog:read_more")}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className="bg-gray-100 ">
        <div className="flex pt-16 overflow-x-scroll md:justify-center sm:flex-wrap sm:overflow-x-auto container-5xl">
          <button
            className={classNames([
              "mb-4 mr-4",
              categoryFilter === "all"
                ? "button-blue-filled"
                : "button-blue-outline",
            ])}
            onClick={() => handleCategoryChange("all")}
          >
            {t("blog:category_all")}
          </button>
          {categories.map((category) => (
            <button
              key={category.slug}
              className={classNames([
                "mb-4 mr-4",
                categoryFilter === category.slug
                  ? "button-blue-filled"
                  : "button-blue-outline",
              ])}
              onClick={() => handleCategoryChange(category.slug)}
            >
              {category.title}
            </button>
          ))}
          <div className="flex-shrink-0 w-px" />
        </div>
        <div className="grid gap-8 pt-4 pb-16 md:pt-16 container-5xl md:grid-cols-2">
          {filteredPosts.map(({ node: post }) => (
            <BlogPostCard
              key={post.id}
              id={post.id}
              title={post.title}
              featuredImage={post.featuredImage}
              excerpt={post.excerpt}
              slug={post.slug}
            />
          ))}
        </div>
      </div>
      <div className="mx-auto my-8 container-5xl">
        <SignupFormCard />
      </div>
    </>
  );
};

export default Blog;

export const query = graphql`
  query BlogQuery($language: String!) {
    posts: allSanityPost(
      filter: { i18n_lang: { eq: $language } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          category {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          excerpt
          featuredImage {
            # gatsbyImageData(width: 600, placeholder: BLURRED)
            asset {
              localFile(width: 600) {
                childImageSharp {
                  gatsbyImageData(width: 300, placeholder: BLURRED)
                }
              }
            }
          }
          largeFeaturedImage: featuredImage {
            asset {
              # gatsbyImageData(width: 1200, placeholder: BLURRED)
              localFile(width: 1200) {
                childImageSharp {
                  gatsbyImageData(width: 1200, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
