import { GatsbyImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { BlogQueryDataPost } from "../../pages/blog";

export interface BlogPostCardProps {
  id: BlogQueryDataPost["id"];
  slug: BlogQueryDataPost["slug"];
  featuredImage: BlogQueryDataPost["featuredImage"];
  title: BlogQueryDataPost["title"];
  excerpt: BlogQueryDataPost["excerpt"];
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  id,
  slug,
  featuredImage,
  title,
  excerpt,
}) => {
  const { t } = useTranslation("blog");
  return (
    <Link
      key={id}
      to={`/blog/${slug.current}`}
      className="flex flex-col w-full p-4 mx-auto overflow-hidden transition-shadow duration-300 bg-white shadow-lg cursor-pointer rounded-2xl hover:shadow-xl"
    >
      {featuredImage.asset && featuredImage.asset.localFile && (
        <GatsbyImage
          image={featuredImage.asset.localFile.childImageSharp.gatsbyImageData}
          // image={featuredImage.asset.gatsbyImageData}
          alt={title}
          className="h-64"
          imgClassName="rounded-lg"
        />
      )}
      <div className="px-2 py-4 space-y-4">
        <h3 className="text-2xl font-bold font-headline">{title}</h3>
        <p className="text-base text-gray-700">
          {`${excerpt.substring(0, 200)}...`}
        </p>
        <div>
          <span className="button-blue-filled">{t("blog:read_more")}</span>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostCard;
